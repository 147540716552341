@import "../../assets/fonts/fonts.css";

$red: #ee3831;

body {
  margin: 0;
  font-family: Swiss721BT-Regular, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
}

h1,
h2,
h3 {
  margin: 0;
  font-family: Swiss721BT-Regular, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 76px;
}

h1 {
  font-family: NHaasGroteskDSPro-95Blk, Helvetica, sans-serif;
  font-size: 76px;
}

main {
  position: absolute;
  height: 100%;
  width: 100%;
  color: $red;
  font-family: Swiss721BT-Regular, Helvetica, sans-serif;
  overflow: hidden;

  footer,
  header {
    display: inline-flex;
    z-index: 4;
    height: 80px;
    width: 100%;
    position: absolute;
    left: 0;
    background-color: white;
  }

  header,
  .list-item {
    cursor: pointer;
    height: 100px;
    display: inline-flex;
    flex-wrap: wrap;
    top: 0;
    align-items: center;
    border-bottom: solid 1px $red;
    & > * {
      margin: 2px 10px 0 10px;
    }
    & > h2 {
      width: 130px;
      font-size: 76px;
      overflow: hidden;
      line-height: 0.7;
      padding-top: 16px;
      padding-left: 10px;
    }
    h1 {
      height: 80px;
      padding-left: 120px;
      width: calc(100% - 465px);
      clip-path: polygon(0 0, 100% 0, calc(100% - 34px) 100%, 0 100%);
      overflow: hidden;
      * {
        height: 100%;
      }
    }
    & > img {
      position: absolute;
      height: 56px;
      right: 0;
      right: 10px;
    }
    & > .arrow {
      display: none;
      position: absolute;
      top: 5px;
      font-size: 24px;
    }
  }

  footer {
    bottom: 0;
    & > *:first-child {
      margin-left: 5px;
    }
    & > *:nth-child(3) {
      margin-left: 65px;
      margin-right: 20px;
    }
    & > *:last-child {
      margin-right: 10px;
    }
    & > .bio {
      padding-top: 7px;
      margin: 10px 15px 10px 0;
      width: calc(100% - 540px);
      flex: auto;
      overflow: scroll;
      text-transform: uppercase;
    }
    .bio::-webkit-scrollbar {
      display: none;
    }
  }

  .player {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: calc(100% - 180px);
    background-color: white;

    & > .headings {
      position: absolute;
      z-index: 2;
      display: inline-flex;
      width: 100%;
      top: 0;
      padding: 20px;
      & > .episode {
        width: 125px;
        margin-right: 145px;
        & > :first-child {
          float: left;
        }
        & > :nth-child(2) {
          float: right;
        }
      }
    }

    .currentTime,
    .duration {
      position: absolute;
      bottom: 0;
      padding: 10px;
      z-index: 2;
    }

    .duration {
      right: 0;
    }

    input[type="range"] {
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; /* Specific width is requi$red for Firefox. */
      background: $red;
      height: 1px;
      z-index: 5;
      margin: 0;
      cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    input[type="range"]:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    input[type="range"]::-ms-track {
      width: 100%;
      cursor: pointer;

      /* Hides the slider so custom styles can be added */
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    /* Special styling for WebKit/Blink */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      z-index: 5;
      background: $red;
      cursor: pointer;
      margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    /* All the same stuff for Firefox */
    input[type="range"]::-moz-range-thumb {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      z-index: 5;
      background: $red;
      cursor: pointer;
      margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
      border: none;
    }

    /* All the same stuff for IE */
    input[type="range"]::-ms-thumb {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: $red;
      cursor: pointer;
      margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
      z-index: 5;
      border: none;
    }
  }
}

@media only screen and (max-width: 980px) {
  main {
    header,
    .list-item {
      position: relative;
      h1 {
        font-size: 14px;
        top: 100px;
        position: absolute;
        width: 100%;
        text-align: center;
        margin: 10px 0;
        padding: 0;
        transition: 200ms;
        &.list {
          top: 73px;
        }
      }
      .arrow {
        display: block;
      }
    }

    .player {
      & > .headings {
        display: none;
      }
    }

    footer {
      display: inline-flex;
      justify-content: space-evenly;
      bottom: 0;
      & > *:first-child {
        margin-left: 0;
      }
      & > *:nth-child(3) {
        margin-left: 0;
        margin-right: 0;
      }
      & > *:last-child {
        margin-right: 0;
      }
      & > .bio {
        display: none;
      }
      & > .grid-button {
        display: none;
      }
    }
  }
}
