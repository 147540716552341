@import "../../assets/fonts/fonts.css";

$red: #ee3831;

body {
    margin: 0;
    color: $red;
}

#loading {
    z-index: 100;
    background-color: white;
    display: inline-flex;
    position: absolute;
    width: calc(100% - 200px);
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    padding: 100px;
    width: calc(100% - 40px);
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;

    & > h1,
    & > h2,
    & > h3 {
        margin: 0;
        font-family: Swiss721BT-Regular, Helvetica, sans-serif;
        font-weight: 300;
        font-size: 86px;
        padding-top: 16px;
        line-height: 1;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 980px) {
    #loading {
        flex-wrap: wrap;
        width: calc(100% - 50px);
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        padding: 25px;
        & > h1,
        & > h2,
        & > h3 {
            font-size: 34px;
            text-align: center;
            padding: 25px;
        }
    }
}
