/**
 * @license
 * MyFonts Webfont Build ID 3841084, 2019-11-26T06:17:12-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: NHaasGroteskDSPro-95Blk by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-95-black/
 * Copyright: Copyright &#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 1,000,000
 * 
 * Webfont: Swiss721BT-Regular by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/swiss-721/std-roman/
 * Copyright: Copyright &#x00A9; 1990 -2018 MyFonts Inc. All rights reserved.
 * Licensed pageviews: 10,000
 * 
 * 
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a9c3c");

  
@font-face {
    font-family: 'NHaasGroteskDSPro-95Blk';
    src: url('webfonts/3A9C3C_0_0.eot');
    src: url('webfonts/3A9C3C_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3A9C3C_0_0.woff2') format('woff2'),url('webfonts/3A9C3C_0_0.woff') format('woff'),url('webfonts/3A9C3C_0_0.ttf') format('truetype');
    font-display: block;
}
  
@font-face {
    font-family: 'Swiss721BT-Regular';
    src: url('webfonts/3A9C3C_1_0.eot');
    src: url('webfonts/3A9C3C_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3A9C3C_1_0.woff2') format('woff2'),url('webfonts/3A9C3C_1_0.woff') format('woff'),url('webfonts/3A9C3C_1_0.ttf') format('truetype');
    font-display: block;
} 