.button {
    text-align: center;
    text-decoration: none;
    height: 60px;
    width: 60px;
    margin: 10px 5px;
    padding: 0;
    border: 1px solid #EE3831;
    border-radius: 50%;
    color: #EE3831;
    background-color: white;
    font-size: 14px;
    text-transform: uppercase;
    transition: 200ms ease-out;
    cursor: pointer;

    &:hover {
        background-color: #EE3831;
        color: white;
    }

    &:active {
        background-color: white;
        color: #EE3831;
    }

    &:focus {
        outline: 0;
    }

    &.active {
        background-color: #EE3831;
        color: white;
    }
}

@media (pointer: coarse) {
    .button {
    
        &:active {
            background-color: #EE3831;
            color: white;
        }
    
        &:hover {
            background-color: white;
            color: #EE3831;
        }
    
        &.active {
            background-color: #EE3831;
            color: white;
        }
    }
}

@media only screen and (max-width: 980px) {
    .button {
        margin: 10px 0;
    }

}