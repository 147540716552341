$red: #ee3831;

.list-item {
    cursor: pointer;
    height: 100px;
    display: inline-flex;
    flex-wrap: wrap;
    top: 0;
    align-items: center;
    border-bottom: solid 1px $red;
    & > * {
        margin: 0px 10px;
    }
    & > h2 {
        width: 130px;
        font-size: 75px;
        overflow: hidden;
        padding-top: 10px;
        margin-left: 20px;
    }
    button {
        margin-left: 50px;
        margin-top: -5px;
    }
    & > h1 {
        width: calc(100% - 465px);
        clip-path: polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0 100%);
        overflow: hidden;
        white-space: nowrap;
        padding-top: 5px;
        * {
            height: 100%;
        }
    }
    & > img {
        position: absolute;
        height: 56px;
        right: 0;
        right: 10px;
        margin-top: 2px;
    }
    & > .arrow {
        position: absolute;
        top: 5px;
        font-size: 24px;
    }
}

.layover {
    z-index: 3;
    position: relative;
    height: calc(100%);
    width: 100%;
    top: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white;
    & > .list-item {
        display: inline-flex;
        height: 100px;
        width: 100%;
        border-bottom: solid 1px $red;
        color: $red;
        text-decoration: none;
        &:hover {
            button {
                background-color: $red;
                color: white;
            }
        }
    }
    &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);
        grid-template-rows: repeat(auto-fill, 300px);
        justify-content: space-between;
        & > .tile {
            width: 260px;
            height: 260px;
            padding: 20px;
            color: $red;
            text-decoration: none;
            & > img {
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                position: relative;
                left: 10px;
                top: 0;
            }
            & > .label {
                display: inline-flex;
                width: 100%;
                & > p,
                h1 {
                    width: 75%;
                    font-size: 14px;
                    margin: 0;
                    text-align: right;
                }
                & > p {
                    text-align: left;
                    width: 25%;
                }
            }
        }
    }

    &.about {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 20px;
        line-height: 1;
        & > .links {
            margin: 76px 0;
            font-size: 76px;
            display: grid;
            width: calc(100% - 40px);
            overflow: hidden;
            grid-template-columns: 2fr 1fr 1fr 2fr 2fr;
            & > a {
                color: $red;
                text-decoration: none;
                padding: 0 2px;
                &.col {
                    &-3 {
                        text-align: right;
                    }
                    &-5 {
                        text-align: right;
                    }
                }
            }
        }
        & > .about {
            text-transform: uppercase;
            margin-bottom: 76px;
        }
    }
}

.about-header {
    h2 {
        width: auto;
        width: calc(100% - 210px);
        line-height: 1;
    }
}

@media only screen and (max-width: 980px) {
    .layover {
        .list-item {
            position: relative;
            & > h1 {
                font-size: 14px;
                top: 100px;
                position: absolute;
                width: 100%;
                text-align: center;
                margin: 10px 0;
                padding: 0;
                transition: 200ms;
                clip-path: none;
                &.list {
                    top: 73px;
                }
            }
        }

        &.about {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            padding: 10px;
            line-height: 1;
            & > h2 {
                // text-align: justify;
                hyphens: auto;
                font-size: 16px;
            }
            & > .links {
                width: 100%;
                margin: 32px 0;
                & > a {
                    font-size: 16px;
                }
            }
        }
    }
}
